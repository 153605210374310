<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('Shaniv packages') }}
    </p>
    <section class="filter-part content-margin d-flex">
      <div class="form-item">
        <label>{{ $t('Select a category') }}</label>
        <div class="mt-2 mb-7 icon-input">
          <input type="text" class="input" />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
      <div class="form-item">
        <label>{{ $t('Select a supplier') }}</label>
        <div class="mt-2 mb-7 icon-input">
          <input type="text" class="input" />
          <img src="/assets/img/icons/search-light.svg" alt="" />
        </div>
      </div>
    </section>
    <section class="table-content-part">
      <div class="d-flex justify-content-between">
        <div class="search-bar">
          <p class="search-label">{{ $t('Search') }}</p>
          <v-text-field
            v-model="search"
            single-line
            hide-details
            class="search-form"
          ></v-text-field>
        </div>
      </div>
      <v-data-table
        :headers="tableHeaders"
        :search="search"
        :items="package_list"
        :options.sync="options"
        :loading="loading"
        :server-items-length="maxPackageNumber"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
        class="cus-table editable-table main-table striped-table"
        item-key="id"
      >
        <template v-slot:[`item.active`]="{ item }">
          <div style="display: flex; justify-content: center">
            <v-checkbox
              color="#0D3856"
              class="cus-checkbox"
              v-model="item.active"
              @change="changeState(item.id, item.active)"
            ></v-checkbox>
          </div>
        </template>
        <template v-slot:[`item.editing`]="{ item }">
          <router-link :to="{name: 'ShanivPackage', params: {id: item.id }}" tag="a">
            <v-icon medium color="dark">edit</v-icon>
          </router-link>
        </template>
        <template v-slot:[`item.image`]="{ item }">
          <v-btn small @click="showImage(item.image)">{{ $t('Show') }}</v-btn>
        </template>
      </v-data-table>
    </section>
    <section class="botton-action">
      <router-link :to="{name: 'ShanivPackageCreate'}" tag="a" class="text-decoration-none">
        <button class="primary-btn bg-yellow">
          {{ $t('Save') }}
        </button>
      </router-link>
      <router-link :to="{name: 'ShanivPackageCreate'}" tag="a" class="text-decoration-none">
        <button class="primary-btn">
          {{ $t('New') }}
        </button>
      </router-link>
    </section>
    <modal
      :width="300"
      :adaptive="true"
      class="imageModal CEModal modal-scroll-bar"
      name="imageModal"
    >
      <button class="button close-btn" @click="closeImageModal()">
        <img src="/assets/img/close.svg" alt />
      </button>
      <img :src="imageUrl" alt="" class="main-image w-100">
    </modal>
    <loading
    :active.sync="loading"
    :can-cancel="false"
    :is-full-page="true"
  ></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: 'CPProductPromotion',
  components: {
    Loading
  },
  data() {
    return {
      search: '',
      imageUrl: '',
      itemsPerPageOptions: [10, 15, 25, 50, 100],
      options: {
        itemsPerPage: 10,
        page: 1
      },
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.shaniv_management.loading,
      package_list: (state) => state.shaniv_management.package_list,
      maxItemNumber: (state) => state.shaniv_management.maxItemNumber,
      maxPackageNumber: (state) => state.shaniv_management.maxPackageNumber,    
    }),
    tableHeaders: function () {
      let itemArray = [];
      for (let i=0; i<this.maxItemNumber; i++) {
        itemArray.push(
          {
            text: this.$t("Item") + " " + (i + 1),
            align: 'center',
            sortable: false,
            value: 'products['+i+'].free_text',
          },
          {
            text: this.$t('Quantity') + " " + (i + 1),
            align: 'center',
            sortable: false,
            value: 'products['+i+'].quantity',
          },
        )
      }
      let headerFront = [
        {
          text: this.$t('Active'),
          align: 'center',
          sortable: false,
          value: 'active',
        },
        {
          text: this.$t('Operation number'),
          align: 'center',
          sortable: false,
          value: 'id',
        },
        {
          text: this.$t('Operation name'),
          align: 'center',
          sortable: false,
          value: 'name',
        },
      ]
      let headerBack = [
        {
          text: this.$t('Sale price'),
          align: 'center',
          sortable: false,
          value: 'price',
        },
        {
          text: this.$t('Operation date'),
          align: 'center',
          sortable: false,
          value: 'operation_data',
        },
        {
          text: this.$t('Operation image'),
          align: 'center',
          sortable: false,
          value: 'image',
        },
        {
          text: this.$t('Editing'),
          align: 'center',
          sortable: false,
          value: 'editing',
        },
      ]

      let headerArray = [...headerFront, ...itemArray, ...headerBack];
      
      return headerArray;
    }
  },
  async created() {
    await this.getPackages(1);
  },
  methods: {
    ...mapActions('shaniv_management', {
      getPackageList: 'getPackageList',
      changePackageState: 'changePackageState'
    }),
    getPackages(page) {
      page = +page || +this.options.page;
      this.options.page = page;
      let itemsPerPage = +this.options.itemsPerPage;
      this.getPackageList({
        'active': 'all',
        per_page: itemsPerPage, 
        page: page-1,  
        isForTable: true
      });
    },
    changeState(id, active) {
      this.changePackageState({'id': id, 'active': active});
    },
    showImage(url) {
      this.imageUrl = url;
      this.$modal.show("imageModal");
      $("html").css("overflow", "hidden");
    },
    closeImageModal() {
      this.$modal.hide("imageModal");
       $("html").css("overflow", "auto");
    },
  },
  watch: {
    options: {
      handler () {
        this.getPackages();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
label {
  margin-bottom: 0;
}
.filter-part {
  .form-item {
    margin-right: 30px;
    max-width: 240px;
  }
}

.botton-action {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 35px;
  .primary-btn {
    margin: 0 15px;
    width: 190px;
    height: 40px;
    font-size: 16px;
  }
}

.imageModal .vm--modal{
  padding: 10px;
  .close-btn {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .table-content-part {
    margin: 20px 20px 0;
  }
}
</style>

<style lang="scss" scoped>
</style>